import * as React from "react"
import Layout from "../components/layout"
import '../style.css'
import Star from "../components/star";

// use git push source troy-dev, instead of git push origin?

//test

const IndexPage = () => {
  return (
    <Layout>
      <main>
        <div className="index-text-container">
          <h1>FERMI GAMMA-RAY SPACE TELESCOPE</h1>
          <Star/>
          <script src='https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_CHTML' async></script>
          <p className="index-blurb">
            NASA’s Fermi Gamma-ray Space Telescope was launched on June 11, 2008 into low Earth orbit, from which it continues to map the entire high-energy gamma-ray sky every ~three hours. The satellite’s name was chosen to honor the famous Italian-American physicist Enrico Fermi, who first proposed the eponymous mechanism for the acceleration of cosmic rays that creates many of the high-energy gamma rays that the satellite detects. NASA’s Fermi mission was built and is operated by an international collaboration between astrophysicists and particle physicists from the United States, Germany, Italy, Japan and Sweden.          
          </p>
        </div>
      </main>    
    </Layout>
  )
}

export default IndexPage